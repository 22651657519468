<template>
  <CRow id="report-component-filter">
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-filter"/>
          Filter
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol sm="6">
              <KundenAuswahl v-model="kunde" form-label="Kunde" form-label-class="d-inline-block" rules="" :delete-button="true"/>
              <ProjektAuswahl v-model="projekt" form-label-class="d-inline-block" :kunde="kunde?kunde.kundenid:''" rules=""/>
              <LeistungsAuswahl v-model="leistung" rules="" :delete-button="true"/>
              <MitarbeiterAuswahl v-model="mitarbeiter" form-label-class="d-inline-block" :disabled="!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_REPORT')" :delete-button="true" rules=""/>
              <DateAuswahl v-model="datumVon" form-label="Datum von" rules="" />
              <DateAuswahl v-model="datumBis" form-label="Datum bis" rules="" />
              <Rechnungsnummer v-on:keydown.native.enter="filterEntries()" v-on:keyup.native.enter="filterEntries()"  @keyup.enter="alert('test')" v-on:submit.prevent="filterEntries()" v-model="rgnummer" form-label="Nummer des Protokolls oder der Rechnung" rules=""/>
            </CCol>
            <CCol sm="6">
              <div class="checkboxen">
                <div class="form-group">
                  <CSwitch color="success" :checked.sync="filterReportAbgeschlossen"/>
                  <span class="label">Arbeiten nicht abgeschlossen</span>
                </div>
                <div class="form-group">
                  <CSwitch color="success" :checked.sync="filterReportNichtSchriftlich"/>
                  <span class="label">Protokoll liegt nicht schriftlich vor</span>
                </div>
                <div class="form-group">
                  <CSwitch color="success" :checked.sync="filterReportNichtBerechnet"/>
                  <span class="label">Protokoll nicht berechnet</span>
                </div>
              </div>

              <div class="checkboxen">
                <div class="form-group">
                  <CSwitch color="success" :checked.sync="tageskalenderEinbeziehen"/>
                  <span class="label">Tageskalendereinträge einbeziehen</span>
                </div>
                <div class="form-group">
                  <CSwitch color="success" :checked.sync="nurTageskalender"/>
                  <span class="label">Nur Tageskalendereinträge</span>
                </div>
                <div class="form-group">
                  <CSwitch color="success" :checked.sync="nurTageskalenderZukuenftigBerechnet"/>
                  <span class="label">Nur Tageskalendereinträge die zukünftig berechnet werden</span>
                </div>
                <div class="form-group">
                  <CSwitch color="success" :checked.sync="alleMitarbeiterStunden"/>
                  <span class="label">Alle Mitarbeiter Stunden anzeigen.</span>
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow class="mt-3">
            <CCol sm="12" class="text-right">
              <CButton type="submit" color="success" v-on:click="filterEntries()" class="d-block" style="width: 100%;">
                <CIcon name="cil-library-add"/> Einträge Filtern
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import KundenAuswahl from '@/components/KundenAuswahl'
import ProjektAuswahl from '@/components/ProjektAuswahl'
import LeistungsAuswahl from '@/components/LeistungsAuswahl'
import MitarbeiterAuswahl from '@/components/MitarbeiterAuswahl'
import DateAuswahl from '@/components/FormElements/Date'
import Rechnungsnummer from '@/components/FormElements/Text'

export default {
  name: 'report-component-filter',
  components: {
    KundenAuswahl,
    ProjektAuswahl,
    LeistungsAuswahl,
    MitarbeiterAuswahl,
    DateAuswahl,
    Rechnungsnummer
  },
  props: {
    value: {
      type: [Array, Object],
      required: true
    }
  },
  created () {
    this.$eventBus.$on('reloadreport', (data) => {
      this.filterEntries()
    })
  },
  data () {
    return {
      kunde: null,
      projekt: null,
      leistung: null,
      mitarbeiter: null,
      datumVon: '',
      datumBis: '',
      rgnummer: '',
      filterReportAbgeschlossen: false,
      filterReportNichtSchriftlich: false,
      filterReportNichtBerechnet: false,
      tageskalenderEinbeziehen: true,
      nurTageskalender: false,
      nurTageskalenderZukuenftigBerechnet: false,
      alleMitarbeiterStunden: false,
      protokolle: [],
      tageskalender: [],
      mitarbeiterZeiten: []
    }
  },
  mounted () {
    // Default Datum von 14 Tage rückwirkend
    this.datumVon = new Date()
    this.datumVon.setDate(this.datumVon.getDate() - 14)
    this.datumBis = new Date()
    if (!this.user.roles.includes('ROLE_ADMIN') && !this.user.roles.includes('ROLE_REPORT')) {
      this.mitarbeiter = this.user.userid
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    },
    lProtokolle () {
      const result = {}
      Vue._.forEach(this.protokolle, function (protokoll) {
        const datum = moment(protokoll.datum).format('DD.MM.YYYY')
        if (!(datum in result)) {
          result[datum] = []
        }
        result[datum].push(protokoll)
      })
      return result
    },
    lTageskalender () {
      const result = {}
      Vue._.forEach(this.tageskalender, function (tageskalender) {
        const datum = moment(tageskalender.datum).format('DD.MM.YYYY')
        if (!(datum in result)) {
          result[datum] = []
        }
        result[datum].push(tageskalender)
      })
      return result
    },
    lMitarbeiter () {
      const result = {}
      const mitarbeiterProtokollFilter = []

      Vue._.forEach(this.protokolle, function (protokoll) {
        const datum = moment(protokoll.datum).format('DD.MM.YYYY')

        Vue._.forEach(protokoll.arbeitszeiten, function (arbeitszeit) {
          if (!(arbeitszeit.mitarbeiter.mitarbeiterid in result)) {
            result[arbeitszeit.mitarbeiter.mitarbeiterid] = []
          }
          if (!(datum in result[arbeitszeit.mitarbeiter.mitarbeiterid])) {
            result[arbeitszeit.mitarbeiter.mitarbeiterid][datum] = []
          }

          if (!mitarbeiterProtokollFilter[arbeitszeit.mitarbeiter.mitarbeiterid]) {
            mitarbeiterProtokollFilter[arbeitszeit.mitarbeiter.mitarbeiterid] = []
          }
          if (!mitarbeiterProtokollFilter[arbeitszeit.mitarbeiter.mitarbeiterid][protokoll.protokollid]) {
            mitarbeiterProtokollFilter[arbeitszeit.mitarbeiter.mitarbeiterid][protokoll.protokollid] = 0
          }
          if (mitarbeiterProtokollFilter[arbeitszeit.mitarbeiter.mitarbeiterid][protokoll.protokollid] !== 1) {
            result[arbeitszeit.mitarbeiter.mitarbeiterid][datum].push(protokoll)
            mitarbeiterProtokollFilter[arbeitszeit.mitarbeiter.mitarbeiterid][protokoll.protokollid] = 1
          }
        })
      })

      Vue._.forEach(this.tageskalender, function (tageskalender) {
        const datum = moment(tageskalender.datum).format('DD.MM.YYYY')
        if (!(tageskalender.mitarbeiter.mitarbeiterid in result)) {
          result[tageskalender.mitarbeiter.mitarbeiterid] = []
        }
        if (!(datum in result[tageskalender.mitarbeiter.mitarbeiterid])) {
          result[tageskalender.mitarbeiter.mitarbeiterid][datum] = []
        }
        result[tageskalender.mitarbeiter.mitarbeiterid][datum].push(tageskalender)
      })
      return result
    }
  },
  watch: {
    protokolle: function () {
      const result = {
        protokolle: this.lProtokolle,
        tageskalender: this.lTageskalender,
        mitarbeiterZeiten: this.lMitarbeiter,
        kunde: this.kunde,
        projekt: this.projekt,
        leistung: this.leistung,
        mitarbeiter: this.mitarbeiter,
        datumVon: moment(this.datumVon, ['DD.MM.YYYY']).hour(0).minute(0).second(1),
        datumBis: moment(this.datumBis, ['DD.MM.YYYY']).hour(23).minute(59).second(59),
        rgnummer: this.rgnummer,
        filterReportAbgeschlossen: this.filterReportAbgeschlossen,
        filterReportNichtSchriftlich: this.filterReportNichtSchriftlich,
        filterReportNichtBerechnet: this.filterReportNichtBerechnet,
        tageskalenderEinbeziehen: this.tageskalenderEinbeziehen,
        nurTageskalender: this.nurTageskalender,
        nurTageskalenderZukuenftigBerechnet: this.nurTageskalenderZukuenftigBerechnet,
        alleMitarbeiterStunden: this.alleMitarbeiterStunden
      }
      this.$emit('input', result)
    },
    tageskalender: function () {
      const result = {
        protokolle: this.lProtokolle,
        tageskalender: this.lTageskalender,
        mitarbeiterZeiten: this.lMitarbeiter,
        kunde: this.kunde,
        projekt: this.projekt,
        leistung: this.leistung,
        mitarbeiter: this.mitarbeiter,
        datumVon: moment(this.datumVon, ['DD.MM.YYYY']).hour(0).minute(0).second(1),
        datumBis: moment(this.datumBis, ['DD.MM.YYYY']).hour(23).minute(59).second(59),
        rgnummer: this.rgnummer,
        filterReportAbgeschlossen: this.filterReportAbgeschlossen,
        filterReportNichtSchriftlich: this.filterReportNichtSchriftlich,
        filterReportNichtBerechnet: this.filterReportNichtBerechnet,
        tageskalenderEinbeziehen: this.tageskalenderEinbeziehen,
        nurTageskalender: this.nurTageskalender,
        nurTageskalenderZukuenftigBerechnet: this.nurTageskalenderZukuenftigBerechnet,
        alleMitarbeiterStunden: this.alleMitarbeiterStunden
      }
      this.$emit('input', result)
    }
  },
  methods: {
    filterEntries () {
      let datumVon = this.datumVon
      if (datumVon !== '') {
        datumVon = moment(datumVon, ['DD.MM.YYYY']).format('DD.MM.YYYY')
      }
      let datumBis = this.datumBis
      if (datumBis !== '') {
        datumBis = moment(datumBis, ['DD.MM.YYYY']).format('DD.MM.YYYY')
      }

      // Protokolle
      if (!this.nurTageskalender) {
        if (!this.nurTageskalenderZukuenftigBerechnet) {
          Vue.axios.post('/report/filter/protokolle', {
            datumVon: this.rgnummer ? null : datumVon,
            datumBis: this.rgnummer ? null : datumBis,
            protokollnr: this.rgnummer,
            mitarbeiter: this.mitarbeiter,
            leistung: this.leistung,
            kunde: (this.kunde ? this.kunde.kundenid : null),
            projekt: this.projekt,
            nichtAbgeschlossen: this.rgnummer ? null : this.filterReportAbgeschlossen,
            nichtSchriftlich: this.rgnummer ? null : this.filterReportNichtSchriftlich,
            nichtBerechnet: this.rgnummer ? null : this.filterReportNichtBerechnet
          }).then((response) => {
            this.protokolle = response.data
            const result = {
              protokolle: this.lProtokolle,
              tageskalender: this.lTageskalender
            }
            this.$emit('input', result)
          }).catch(() => {
            this.protokolle = []
          })
        } else {
          // Nur Anzeige von Tageskalender-Einträgen, die zukünfig berechnet werden können
          this.protokolle = []
        }
      } else {
        // Switch nur Tageskalender auf An
        this.protokolle = []
      }
      // Tageskalender
      if (!this.leistung || this.leistung === '') {
        if (!this.filterReportAbgeschlossen) {
          if (!this.filterReportNichtSchriftlich) {
            if (!this.filterReportNichtBerechnet) {
              if (this.tageskalenderEinbeziehen || this.nurTageskalender) {
                Vue.axios.post('/report/filter/tageskalender', {
                  datumVon: datumVon,
                  datumBis: datumBis,
                  tageskalendernr: this.rgnummer,
                  mitarbeiter: this.mitarbeiter,
                  kunde: (this.kunde ? this.kunde.kundenid : ''),
                  projekt: this.projekt,
                  zukuenftigBerechnen: this.nurTageskalenderZukuenftigBerechnet
                }).then((response) => {
                  this.tageskalender = response.data
                  const result = {
                    protokolle: this.lProtokolle,
                    tageskalender: this.lTageskalender
                  }
                  this.$emit('input', result)
                }).catch(() => {
                  this.tageskalender = []
                })
              } else {
                // Tageskalender wird nur geladen, wenn Schalter auf an Ist
                this.tageskalender = []
              }
            } else {
              // Filter Protokoll nicht berechnet kann keinen Tageskalender liefern
              this.tageskalender = []
            }
          } else {
            // Filter nicht schriftlich kann nur Protokolle betreffen
            this.tageskalender = []
          }
        } else {
          // Filter Arbeiten nicht abgeschlossen geht nur auf die Protokolle
          this.tageskalender = []
        }
      } else {
        // Tageskalender-Einträge besitzen keine Leistung
        this.tageskalender = []
      }
    }
  }
}
</script>

<style lang="scss">
  div#report-component-filter {
    div.form-group, div.leistung {
      margin-bottom: 0.2rem;
      label {
        font-size: 0.7rem;
        font-style: italic;
        margin-bottom: 0;
      }
      strong {
        font-weight: normal;
      }
    }
    div.checkboxen {
      clear: both;
      display: inline-block;
      margin-top: 1.4rem;
      div.form-group {
        clear: both;
        display: inline-block;
        width: 100%;
        label {
          float: left;
        }
        span.label {
          font-size: 0.9rem;
          font-style: normal;
          display: inline-block;
          margin-left: 0.6rem;
          float: left;
        }
      }
    }
  }
</style>
