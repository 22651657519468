<template>
  <div id="list-protokolle" v-if="protokolle">
    <br/>
    <CCol v-if="Object.keys(protokolle).length > 0" sm="12" class="text-right">
      <strong class="text-right">
        Gesamt <span v-if="Object.keys(protokolle).length-1 > 0">({{protokolle[Object.keys(protokolle)[Object.keys(protokolle).length-1]][0].datum|moment("DD.MM.YYYY")}} - {{protokolle[Object.keys(protokolle)[0]][0].datum|moment("DD.MM.YYYY")}})</span>:
        {{ parseFloat(getSum(protokolle)).toFixed(2) }} Stunden
      </strong>
    </CCol>
    <div v-for="(tag, index, count) in protokolle" v-bind:key="index">
      <hr/>
      <CRow>
        <CCol sm="6" class="">
          <strong class="">
            Gesamt: ({{tag[0].datum|moment("DD.MM.YYYY")}}):
            <span v-if="Array.isArray(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }))">
            {{
                parseFloat(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }).reduce(function (partialSum3, c) { return parseFloat(partialSum3) + parseFloat(c); })).toFixed(2)
              }} Stunden
          </span>
            <span v-else>
            {{ parseFloat(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  })).toFixed(2) }} Stunden
          </span>
          </strong>
        </CCol>
        <CCol sm="6" class="text-right">
          <strong v-if="(count-1) >= 0" class="text-right">
            Gesamt: ({{protokolle[Object.keys(protokolle)[count-1]][0].datum|moment("DD.MM.YYYY")}}):
            <span v-if="Array.isArray(protokolle[Object.keys(protokolle)[count-1]].map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }))">
            {{
                parseFloat(protokolle[Object.keys(protokolle)[count-1]].map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }).reduce(function (partialSum3, c) { return parseFloat(partialSum3) + parseFloat(c); })).toFixed(2)
              }} Stunden
          </span>
            <span v-else>
            {{ parseFloat(protokolle[Object.keys(protokolle)[count-1]].map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  })).toFixed(2) }} Stunden
          </span>
          </strong>
        </CCol>
      </CRow>
      <hr/>
      <div v-for="(protokoll, prtindex) in tag" v-bind:key="prtindex">
        <Protokoll :protokoll="protokoll"/>
      </div>
      <hr v-if="(count >= Object.keys(protokolle).length-1)" />
      <CRow v-if="(count >= Object.keys(protokolle).length-1)">
        <CCol sm="6" class="">
        </CCol>
        <CCol sm="6" class="text-right">
          <strong class="">
            Gesamt: ({{tag[0].datum|moment("DD.MM.YYYY")}}):
            <span v-if="Array.isArray(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }))">
            {{
                parseFloat(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }).reduce(function (partialSum3, c) { return parseFloat(partialSum3) + parseFloat(c); })).toFixed(2)
              }} Stunden
          </span>
            <span v-else>
            {{ parseFloat(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  })).toFixed(2) }} Stunden
          </span>
          </strong>
        </CCol>
      </CRow>
    </div>
    <CCol v-if="Object.keys(protokolle).length > 0" sm="12" class="text-right">
      <strong class="text-right">
        Gesamt <span v-if="Object.keys(protokolle).length-1 > 0">({{protokolle[Object.keys(protokolle)[Object.keys(protokolle).length-1]][0].datum|moment("DD.MM.YYYY")}} - {{protokolle[Object.keys(protokolle)[0]][0].datum|moment("DD.MM.YYYY")}})</span>:
        {{ parseFloat(getSum(protokolle)).toFixed(2) }} Stunden
      </strong>
    </CCol>
    <CCol v-else>
      <br/>
      <strong class="text-right">
        Keine Einträge zu diesem Filter gefunden.
      </strong>
      <br/>
      Versuche Sie es erneut mit anderen Filtern.
    </CCol>
  </div>
</template>

<script>
import Protokoll from '@/views/Report/components/entries/Protokoll'

export default {
  name: 'report-components-list-protokolle',
  components: {
    Protokoll
  },
  props: {
    protokolle: {
      type: [Array, Object],
      required: true
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    getSum (protokolle) {
      var map = []

      Object.keys(protokolle).forEach(function (key, index) { map[index] = protokolle[key] })
      map = map.map(protokoll => {
        if (Array.isArray(protokoll)) {
          return protokoll.map(mitarbeiter => {
            if (Array.isArray(mitarbeiter.arbeitszeiten)) {
              return mitarbeiter.arbeitszeiten.map(protokoll2 => protokoll2.dauer)
            } else {
              return mitarbeiter
            }
          })
        } else {
          return false
        }
      })
      return this.getSumOfArrays(map)
    },
    getSumOfArrays (map) {
      if (Array.isArray(map) && map.length > 0) {
        map = map.flat(3)
        if (Array.isArray(map) && map.length > 0) {
          map = map.reduce(function (partialSum, a) {
            return parseFloat(partialSum) + parseFloat(a)
          })
        }
      }
      return map
    }
  }
}
</script>
