<template>
  <CRow class="form-group projekt-auswahl-formgroup">
    <CCol sm="12">
      <label :class="formLabelClass">{{formLabel}}</label>
      <ValidationProvider :rules="rules" immediate v-slot="{ errors }">
        <v-select
          ref="projektselect"
          v-model="filterValue"
          placeholder="Bitte wählen Sie ein Projekt aus ..."
          :options="projekte"
          :clearable="deleteButton"
          :class="isValidClass(errors)"
          v-on:input="inputEvent"
        >
          <template #no-options>
            Keine Projekte gefunden.
          </template>
        </v-select>

      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ProjektAuswahl',
  props: {
    value: [Number, String, Event],
    formLabel: {
      default: 'Projekte des Kunden',
      type: String
    },
    formLabelClass: {
      default: 'd-none',
      type: String
    },
    rules: {
      default: 'required',
      type: String
    },
    deleteButton: {
      default: false,
      type: Boolean
    },
    kunde: {
      type: [Number, String]
    }
  },
  data () {
    return {
      isValid: false,
      filterValue: null,
      projektDB: null
    }
  },
  watch: {
    value: function () {
      this.valueToLocal()
    },
    kunde: function () {
      this.$emit('input', '')
      if (this.kunde) {
        this.loadProjekte().then((response) => {
          this.valueToLocal()
        })
      }
    }
  },
  mounted () {
    if (this.kunde) {
      this.loadProjekte().then((response) => {
        this.valueToLocal()
      })
    }
  },
  computed: {
    projekte () {
      const options = []
      const self = this
      this._.forEach(this.projektDB, function (projekt) {
        const tmp = {
          code: projekt.projektid,
          label: self.generateLabelName(projekt)
        }
        options.push(tmp)
      })
      return options
    }
  },
  methods: {
    valueToLocal: function () {
      this.filterValue = this.value
      if (this.value) {
        this.isValid = true
      }
      // Value nach Projekt
      const self = this
      if (this.filterValue && this.projekte) {
        const arrayid = Vue._.findIndex(this.projekte, function (p) { return p.code === self.filterValue })
        if (arrayid !== -1) {
          this.filterValue = this.projekte[arrayid]
        }
      }
    },
    inputEvent: function (newValue) {
      if (newValue) {
        this.isValid = true
        this.$emit('input', newValue.code)
      } else {
        // Null, bzw. delete-Button
        this.$emit('input', null)
      }
    },
    generateLabelName: function (projekt) {
      return projekt.name
    },
    isValidClass: function (errors) {
      let result = 'form-control'
      if (errors.length === 0) {
        result = result + ' is-valid'
      } else {
        result = result + ' is-invalid'
      }
      return result
    },
    loadProjekte: function () {
      if (this.kunde) {
        return Vue.axios.get('/projekte/get/' + ((typeof this.kunde === 'object') ? this.kunde.kundenid : this.kunde))
          .then((response) => {
            this.projektDB = response.data.filter(projekt => {
              return projekt.aktiv
            })
          })
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
div.projekt-auswahl-formgroup {
  div.form-control {
    padding: 1px 0 0 1px;
    div.vs__dropdown-toggle {
      border: none;
    }
  }
  div.v-select {
    height: auto;
  }
}
</style>
