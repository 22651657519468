<template>
  <div class="report-protokoll basisinfo mt-1 p-1" v-if="protokoll">

    <CRow>
      <CCol sm="1">
        <small class="d-block">
          {{protokoll.datum|moment("DD.MM.YYYY")}}
        </small>
        <strong class="d-block">
          Prot. {{protokoll.protokollid}}
        </strong>
        <small class="d-block" style="white-space: nowrap;" v-if="protokoll.rechnungsnummer">
          Rg. {{protokoll.rechnungsnummer}}
        </small>
        <CButtonGroup class="mt-1">
          <CButton
            color="danger"
            size="sm"
            v-if="trickVue.$roleBasedSecurity.userHasRole(['ROLE_ADMIN'])"
            v-c-tooltip="{content: 'Protokoll Löschen', placement:'right'}"
            v-on:click="deleteProtokoll(protokoll.protokollid)"
          >
            <CIcon name="cil-trash" size="sm"/>
          </CButton>
          <CButton
            color="success"
            size="sm"
            v-if="protokoll.rechnungsnummer == ''"
            v-c-tooltip="{content: 'Rechnungsnummer Eintragen', placement:'right'}"
            v-on:click="rechnungsnummer(protokoll.protokollid)"
          >
            <CIcon name="cil-euro" size="sm"/>
          </CButton>
        </CButtonGroup>
      </CCol>

      <CCol sm="1"/>

      <CCol sm="3">
        <span>
          <em class="d-block">Kunde:</em>
          {{protokoll.kunde.name1}}
        </span>
        <span v-if="protokoll.projekt" class="d-block mt-1">
          <em class="d-block">Projekt:</em>
          {{protokoll.projekt.name}}
        </span>
        <span v-if="protokoll.leistung" class="d-block mt-1">
          <em class="d-block">Leistung:</em>
          {{protokoll.leistung.name}}
        </span>
      </CCol>

      <CCol sm="4" class="arbeitszeit">
        <CRow class="teammitglied" v-for="(arbeitszeit, index) in protokoll.arbeitszeiten" v-bind:key="index">
          <CCol sm="8">
            <small class="d-block">
              {{trickVue.calculateTitel(arbeitszeit.mitarbeiter.titel)}}
            </small>
            <span class="d-block">
              {{arbeitszeit.mitarbeiter.name}}
            </span>
          </CCol>
          <CCol sm="4" class="zeiten text-right">
            <small>{{arbeitszeit.datum|moment("DD.MM.YYYY")}}</small>
            <span v-if="!protokoll.tageskalender" style="color: red; font-weight: bold;" class="d-block">
                {{arbeitszeit.dauer|number('0.00')}} Std.
                <CIcon name="cil-bell-exclamation" v-if="arbeitszeit.auto"/>
             </span>
            <span v-else class="d-block">
                {{arbeitszeit.dauer|number('0.00')}} Std.
                <CIcon name="cil-bell-exclamation" v-if="arbeitszeit.auto"/>
             </span>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" class="text-right">
            <strong class="text-right">Gesamt: {{ protokoll.arbeitszeiten.reduce((partialSum, a) => partialSum + parseFloat(a.dauer), 0) }}</strong>
          </CCol>
        </CRow>
      </CCol>

      <CCol sm="2" class="arbeitsbeschreibung">
        {{protokoll.arbeitsbeschreibung|truncate(120)}}
      </CCol>

      <CCol sm="1" class="buttons text-right">
        <CButtonGroup :vertical="true">
          <CButton
            :color="booleanColor(protokoll.abgeschlossen)"
            size="sm"
            v-on:click="changeStatus('abgeschlossen', protokoll.protokollid)"
            v-c-tooltip="{content: 'Abgeschlossen?', placement:'left'}"
          >
            <CIcon name="cil-lock-unlocked" size="sm" v-if="!protokoll.abgeschlossen"/>
            <CIcon name="cil-lock-locked" size="sm" v-if="protokoll.abgeschlossen"/>
          </CButton>
          <CButton
            :color="booleanColor(protokoll.schriftlich)"
            size="sm"
            v-on:click="changeStatus('schriftlich', protokoll.protokollid)"
            v-c-tooltip="{content: 'Schriftlich?', placement:'left'}"
          >
            <CIcon name="cil-paperclip" size="sm"/>
          </CButton>
          <CButton
            :color="trickVue.booleanColor(protokoll.berechnet)"
            size="sm"
            v-on:click="changeStatus('berechnet', protokoll.protokollid)"
            v-c-tooltip="{content: 'Berechnet?', placement:'left'}"
          >
            <CIcon name="cil-euro" size="sm"/>
          </CButton>
          <CButton
            color="info"
            size="sm"
            v-on:click="showBilder(protokoll.protokollid)"
            v-c-tooltip="{content: 'Bilder anzeigen', placement:'left'}"
          >
            <CIcon name="cil-image" size="sm"/>
          </CButton>
          <CButton
            color="success"
            size="sm"
            v-on:click="print(protokoll.protokollid)"
            v-c-tooltip="{content: 'Protokoll ausdrucken', placement:'left'}"
          >
            <CIcon name="cil-print" size="sm"/>
          </CButton>
        </CButtonGroup>
      </CCol>
    </CRow>

    <CAlert color="info" class="m-1 p-2" v-if="!protokoll.tageskalender">
      <i class="fa fa-exclamation-triangle"></i>
      Arbeitszeiten dieses Protokolls werden im Tageskalender nicht berücksichtigt (<strong>rot</strong>).
    </CAlert>

    <CAlert color="info" class="m-1 p-2" v-if="trickVue.hasAuto(protokoll.arbeitszeiten)">
      <i class="fa fa-exclamation-triangle"></i>
      Manche Arbeitszeiten dieses Protokolls wurden diesem automatisch hinzugefügt (<strong><CIcon name="cil-bell-exclamation" /></strong>)
    </CAlert>

    <CModal
      :title="'Rechnungsnummer für Protokoll ' + idModal"
      color="info"
      size="lg"
      :show.sync="protokollModal"
    >
      <Rechnungsnummer v-model="reload" :protokollid="idModal"/>
      <template #footer>&nbsp;</template>
    </CModal>

    <CModal
      :title="'Bilder für Protokoll ' + idBilderModal"
      color="info"
      size="xl"
      :show.sync="bilderModal"
    >
      <Bilder :protokollid="idBilderModal"/>
      <template #footer>&nbsp;</template>
    </CModal>

  </div>
</template>

<script>
import Vue from 'vue'
import Rechnungsnummer from '@/views/Report/components/Rechnungsnummer'
import Bilder from '@/views/Report/components/Bilder'

export default {
  name: 'report-components-entry-protokoll',
  components: {
    Rechnungsnummer,
    Bilder
  },
  props: {
    protokoll: {
      type: Object,
      required: true
    },
    from: {
      type: Date,
      required: false
    },
    to: {
      type: Date,
      required: false
    }
  },
  data () {
    return {
      statusTitel: [
        { value: '10', label: 'Helfer' },
        { value: '20', label: 'Programmierer' },
        { value: '30', label: 'Monteur' },
        { value: '40', label: 'Obermonteur' },
        { value: '50', label: 'Kundendiensttechniker' },
        { value: '60', label: 'Meister' },
        { value: '70', label: 'Systemtechniker' }
      ],
      protokollModal: false,
      bilderModal: false,
      idModal: null,
      idBilderModal: null,
      reload: false
    }
  },
  computed: {
    trickVue () {
      // TODO: this nicht vorhanden in gekapselten v-for ???
      return this
    }
  },
  watch: {
    reload: function () {
      if (this.reload) {
        this.idModal = null
        this.protokollModal = false
        this.$snotify.success('Die Rechnungsnummer wurde eingetragen.', {
          position: 'rightTop',
          timeout: 4000
        })
        const self = this
        setTimeout(function () {
          self.$eventBus.$emit('reloadreport', [])
        }, 2000)
      }
    }
  },
  methods: {
    calculateTitel: function (titel) {
      let result = titel
      Vue._.forEach(this.statusTitel, function (sTitel) {
        if (sTitel.value === titel) {
          result = sTitel.label
        }
      })
      return result
    },
    booleanColor: function (checkAgainst) {
      if (checkAgainst) {
        return 'success'
      } else {
        return 'danger'
      }
    },
    hasAuto: function (arbeitszeiten) {
      let result = false
      Vue._.forEach(arbeitszeiten, function (arbeitszeit) {
        if (arbeitszeit.auto) {
          result = true
        }
      })
      return result
    },
    print: function (protokollid) {
      this.$router.push({ path: `/print/${protokollid}` })
    },
    deleteProtokoll: function (protokollid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Den Eintrag wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/protokoll/delete/' + protokollid)
            .then((response) => {
              self.$snotify.error('Der Eintrag wurde gelöscht, die Grunddaten werden neu ermittelt.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$eventBus.$emit('reloadreport', [])
            })
        })
    },
    changeStatus: function (status, protokollid) {
      const self = this
      Vue.axios.put('/protokoll/status/' + status + '/' + protokollid)
        .then((response) => {
          self.$snotify.error('Der Status wurde geändert, die Grunddaten werden neu ermittelt.', {
            position: 'rightTop',
            timeout: 4000
          })
          self.$eventBus.$emit('reloadreport', [])
        })
    },
    rechnungsnummer: function (protokollid) {
      this.idModal = protokollid
      this.protokollModal = true
    },
    showBilder: function (protokollid) {
      this.idBilderModal = protokollid
      this.bilderModal = true
    }
  }
}
</script>
