<template>
  <div id="list-tageskalender" v-if="tageskalender">
    <br/>
    <CCol  v-if="Object.keys(tageskalender).length > 0" sm="12" class="text-right">
    <strong class="text-right">
      Gesamt <span v-if="Object.keys(tageskalender).length-1 > 0">({{tageskalender[Object.keys(tageskalender)[Object.keys(tageskalender).length-1]][0].datum|moment("DD.MM.YYYY")}} - {{tageskalender[Object.keys(tageskalender)[0]][0].datum|moment("DD.MM.YYYY")}})</span>:
      <span>
        {{ getSum(tageskalender) }} Stunden
      </span>
    </strong>
    </CCol>
    <div v-for="(tag, index, count) in tageskalender" v-bind:key="index">
      <hr/>
      <CRow>
        <CCol sm="6" class="">
          <strong class="">
            Gesamt ({{tag[0].datum|moment("DD.MM.YYYY")}}):
            <span v-if="true">
          {{ getSum(tag) }} Stunden
          </span>
            <span v-else>
          </span>
          </strong>
        </CCol>
        <CCol sm="6" class="text-right">
          <strong v-if="(count-1) >= 0" class="">
            Gesamt ({{ Object.keys(tageskalender)[(count-1)] }}):
            <span class="text-right">
              {{ getSum(tageskalender[Object.keys(tageskalender)[count-1]]) }} Stunden
            </span>
          </strong>
        </CCol>
      </CRow>
      <hr/>
      <div v-for="(tkalender, tkindex) in tag" v-bind:key="tkindex">
        <Tageskalender :tageskalender="tkalender"/>
      </div>
      <hr  v-if="(count >= Object.keys(tageskalender).length-1)" />
      <CRow v-if="(count >= Object.keys(tageskalender).length-1)">
        <CCol sm="6" class="">
        </CCol>
        <CCol sm="6" class="text-right">
          <strong v-if="(count-1) >= 0" class="">
            Gesamt: ({{ Object.keys(tageskalender)[(count-1)] }}):
            <span v-if="true">
          {{ getSum(tageskalender[Object.keys(tageskalender)[count-1]]) }} Stunden
          </span>
            <span v-else>
          </span>
          </strong>
        </CCol>
      </CRow>
    </div>
    <hr v-if="Object.keys(tageskalender).length > 0"/>
    <CCol v-if="Object.keys(tageskalender).length > 0" sm="12" class="text-right">
      <strong class="text-right">
        Gesamt <span v-if="Object.keys(tageskalender).length-1 > 0">({{tageskalender[Object.keys(tageskalender)[Object.keys(tageskalender).length-1]][0].datum|moment("DD.MM.YYYY")}} - {{tageskalender[Object.keys(tageskalender)[0]][0].datum|moment("DD.MM.YYYY")}})</span>:
        <span>
        {{ getSum(tageskalender) }} Stunden
      </span>
      </strong>
    </CCol>
    <CCol v-else>
      <br/>
      <strong class="text-right">
        Keine Einträge zu diesem Filter gefunden.
      </strong>
      <br/>
      Versuche Sie es erneut mit anderen Filtern.
    </CCol>
  </div>
</template>

<script>
import Tageskalender from '@/views/Report/components/entries/Tageskalender'

export default {
  name: 'report-components-list-tageskalender',
  components: {
    Tageskalender
  },
  props: {
    tageskalender: {
      type: [Array, Object],
      required: true
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    getSum (tageskalender) {
      var map = []

      Object.keys(tageskalender).forEach(function (key, index) { map[index] = tageskalender[key] })
      map = map.map(tageskalenderEntry => {
        if (Array.isArray(tageskalenderEntry)) {
          return this.getSum(tageskalenderEntry)
        } else {
          return tageskalenderEntry.dauer
        }
      })

      return this.getSumOfArrays(map)
    },
    getSumOfArrays (map) {
      if (Array.isArray(map) && map.length > 0) {
        map = map.flat(3)
        if (Array.isArray(map) && map.length > 0) {
          map = map.reduce(function (partialSum, a) {
            return parseFloat(partialSum) + parseFloat(a)
          })
        }
      }
      return map
    },
    flatArray (array, number) {
      return array.flat(number)
    }
  }
}
</script>
