<template>
  <div class="report-tageskalender basisinfo mt-1 p-1" v-if="tageskalender">

    <CRow>

      <CCol sm="1">
        {{tageskalender.datum|moment("DD.MM.YYYY")}}
      </CCol>

      <CCol sm="1">
        <CButtonGroup :vertical="true">
          <CButton
            color="success"
            size="sm"
            v-c-tooltip="{content: 'In Protokoll umwandeln', placement:'left'}"
            v-on:click="tkCopy(tageskalender.tageskalenderid)"
          >
            <CIcon name="cil-calendar" size="sm"/>
          </CButton>
          <CButton
            color="info"
            size="sm"
            v-on:click="showBilder(tageskalender.tageskalenderid)"
            v-c-tooltip="{content: 'Bilder anzeigen', placement:'left'}"
          >
            <CIcon name="cil-image" size="sm"/>
          </CButton>
          <CButton
            color="danger"
            size="sm"
            v-if="trickVue.$roleBasedSecurity.userHasRole(['ROLE_ADMIN'])"
            v-c-tooltip="{content: 'Eintrag aus Tageskalender Löschen', placement:'left'}"
            v-on:click="tkDelete(tageskalender.tageskalenderid)"
          >
            <CIcon name="cil-trash" size="sm"/>
          </CButton>
        </CButtonGroup>
      </CCol>

      <CCol sm="4">
        <p>
          <em>Kunde:</em>
          {{tageskalender.kunde.name1}}
        </p>
        <p v-if="tageskalender.projekt">
          <em>Projekt:</em>
          {{tageskalender.projekt.name}}
        </p>
      </CCol>

      <CCol sm="4" class="mitarbeiter">
        <em>Mitarbeiter:</em>
        {{tageskalender.mitarbeiter.name}}
      </CCol>

      <CCol sm="2" class="arbeitszeit text-right">
        {{tageskalender.dauer|number('0.00')}} Std.
      </CCol>

    </CRow>

    <CRow>
      <CCol sm="2"></CCol>
      <CCol sm="10" class="arbeitsbeschreibung">
        {{tageskalender.taetigkeit|truncate(120)}}
      </CCol>
    </CRow>

    <CAlert v-if="tageskalender.zukuenftigBerechnen" color="info">
      <CIcon name="cil-library-add" size="sm"/>
      Dieser Tageskalender-Eintrag wird dem Kunden zukünftig berechnet, und in ein Protokoll umgewandelt.
    </CAlert>

    <CModal
      title="Tageskalender in Protokoll umwandeln"
      color="info"
      size="xl"
      :show.sync="protokollModal"
    >
      <TageskalenderCopy v-model="reload" :tageskalenderid="idModal"/>
      <template #footer>&nbsp;</template>
    </CModal>

    <CModal
      :title="'Bilder für Protokoll ' + idBilderModal"
      color="info"
      size="xl"
      :show.sync="bilderModal"
    >
      <Bilder :tageskalenderid="idBilderModal"/>
      <template #footer>&nbsp;</template>
    </CModal>
  </div>
</template>

<script>
import Vue from 'vue'
import TageskalenderCopy from '@/views/Report/components/Copy'
import Bilder from '@/views/Report/components/Bilder'
import moment from 'moment'

export default {
  name: 'report-components-entry-tageskalender',
  components: {
    TageskalenderCopy,
    Bilder
  },
  props: {
    tageskalender: {
      type: Object,
      required: true
    },
    from: {
      type: Date,
      required: false
    },
    to: {
      type: Date,
      required: false
    }
  },
  data () {
    return {
      protokollModal: false,
      idModal: null,
      reload: false,
      bilderModal: false,
      idBilderModal: null
    }
  },
  computed: {
    trickVue () {
      // TODO: this nicht vorhanden in gekapselten v-for ???
      return this
    }
  },
  watch: {
    reload: function () {
      if (this.reload) {
        this.idModal = null
        this.protokollModal = false
        this.$snotify.success('Neues Protokoll wurde gespeichert, Tageskalender-Eintrag gelöscht.', {
          position: 'rightTop',
          timeout: 4000
        })
        const self = this
        setTimeout(function () {
          self.$eventBus.$emit('reloadreport', [])
        }, 2000)
      }
    }
  },
  methods: {
    moment: function (date) {
      return moment(date)
    },
    tkDelete: function (tageskalenderid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Den Eintrag wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/tageskalender/deleteforall/' + tageskalenderid)
            .then((response) => {
              self.$snotify.error('Der Tageskalender-Eintrag wurde gelöscht, die Grunddaten werden neu ermittelt.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$eventBus.$emit('reloadreport', [])
            })
        })
    },
    tkCopy: function (tageskalenderid) {
      this.idModal = tageskalenderid
      this.protokollModal = true
    },
    showBilder: function (tageskalenderid) {
      this.idBilderModal = tageskalenderid
      this.bilderModal = true
    }
  }
}
</script>
