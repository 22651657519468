<template>
  <div class="rechnungsnummer">
    <CForm class="no-mb" @submit.prevent="newRechnungsnummer()">
      <ValidationObserver v-slot="{ invalid }">
        <CRow>
          <CCol sm="12">
            <RechnungsnummerText v-model="rechnungsnummer" form-label="Rechnungsnummer"/>
          </CCol>
        </CRow>

        <CRow>
          <CCol col="12" class="text-right">
            <CButton color="success" class="btn-block px-4" :disabled="invalid" type="submit">Rechnungsnummer eintragen</CButton>
          </CCol>
        </CRow>
      </ValidationObserver>
    </CForm>
  </div>
</template>

<script>
import Vue from 'vue'
import RechnungsnummerText from '@/components/FormElements/Text'

export default {
  name: 'report-rechnungsnummer',
  components: {
    RechnungsnummerText
  },
  props: {
    protokollid: [Number, String],
    value: [Boolean]
  },
  data () {
    return {
      rechnungsnummer: ''
    }
  },
  watch: {
    protokollid: function () {
      this.setDefaults()
    }
  },
  methods: {
    setDefaults () {
      this.rechnungsnummer = ''
    },
    newRechnungsnummer: function () {
      Vue.axios.put('/protokoll/rechnungsnummer/' + this.protokollid, {
        rechnungsnummer: this.rechnungsnummer
      }).then((response) => {
        this.setDefaults()
        this.$emit('input', true)
      })
    }
  }
}
</script>
